import { unionFeatureFlip } from './helpers/unionEnvironment';

export const defaultProdFeatureFlip = [
	{
		name: 'resell',
		activated: false,
		description: 'resell feature',
	},
	{
		name: 'callFaucetOnLaunch',
		activated: true,
		description: 'request poa faucet on call',
	},
	{
		name: 'blockChainWatcherEventOff',
		activated: false,
		description: 'turn OFF blochain event',
	},
	{
		name: 'deletePassport',
		activated: false,
		description: 'delete a passport',
	},
	{
		name: 'brandsList',
		activated: false,
		description: 'add brand list in tab',
	},
	{
		name: 'hideNetworkBanner',
		activated: false,
		description: 'always hide network banner',
	},
	{
		name: 'lost',
		activated: false,
		description: 'lost or stolen feature',
	},
	{
		name: 'monoBrand',
		activated: false,
		description: 'display only one brand',
	},
	{
		name: 'exchange',
		activated: false,
		description: 'exchange',
	},
	{
		name: 'newProductDetail',
		activated: true,
		description: 'Redesign product detail',
	},
	{
		name: 'newCollectionsList',
		activated: true,
		description: 'Redesign collections list',
	},
	{
		name: 'newNotificationsPage',
		activated: true,
		description: 'Redesign notifications',
	},
	{
		name: 'landingClaimWithAddress',
		activated: false,
		description: 'Show claim with address form on the landing page',
	},
	{
		name: 'newSettingsPage',
		activated: true,
		description: 'Redesign settings page',
	},
	{
		name: 'darkMode',
		activated: false,
		description: 'Enable dark mode theme',
	},
];
export const featureFlipCommonDefautPROD = unionFeatureFlip(
	defaultProdFeatureFlip,
);
